<template>
  <div class="container" v-if="content">
    <breadcrumb :current="{ title: content.title }"></breadcrumb>
    <div class="video-box">
      <h1>{{ content.title }}</h1>
      <div class="info">
        <span>来源:{{  content.origin }}</span>
      
      </div>
      <video  style="width: 100%;" :src="content.videoUrl" controls></video>
     
    </div>
     <!-- 选项卡 -->
     <category isDetail @change="changeCategory" :categorys="categorys"></category>
     <txt-view v-if="categoryIndex === 0" class="txt" :txt="content.txt"></txt-view>
     <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
     <recommend url="video" :id="$route.params.id"  :contentType="$route.meta.contentType" />
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

//引入 hls,视频直播(m3u8)必须引入的
import 'videojs-contrib-hls'
//播放rtmp视频
import 'videojs-flash'


import {getLiveDetail} from "@/api/video/video";

import MainInfo from "@/components/MainInfo";
import Recommend from '@/components/RelatedRecommend'


export default {
  name:'video-detail',
  components: {
    MainInfo,Recommend,videoPlayer
  },
  watch: {
     $route(){
      this.getData()

     } 
    },
  data() {
    return {
      src: '',
      initialized: false,
      currentTech: 'Html5',
      streams: {
        rtmp: '',
        hls: ''
      },
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        overNative: true,
        muted: true,
        autoplay: true,
        controls: true,
        techOrder: ['html5'],
        preload: "auto",
        sourceOrder: true,
        language: "zh-CN",
        // aspectRatio: "4:3",
        flash: {
          hls: { withCredentials: false }
        },
        html5: { hls: { withCredentials: false } },
        // fluid:true,
        sources: [
          {
            // src: this.src,
            // src:this.src
            // withCredentials: false,
            // type: 'application/x-mpegURL',
            src: '',
            // src: 'https://zhpj.ydy121.com/livedh/8F07CA4PAG882B5/0/1/20220725T004212/b69445d33f8e2a222a030b88875b1d1a.m3u8?proto=https'
          }
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },




      content:null,
      categoryIndex: 0,//分类下标
      categorys:[
            {
                name: '视频详情',
                newsType: 1
            },{
                name: '评论',
                newsType: 2
            }
      ]
    }
  },
  created () {
      this.getData()
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    currentStream() {
      return this.currentTech === 'Flash' ? 'RTMP' : 'HLS'
    }
  },
  methods: {
    onPlayerReadied() {
      if (!this.initialized) {
        this.initialized = true
        // this.currentTech = this.player.techName_
      }
    },
    // record current time
    onTimeupdate(e) {
      // console.log('currentTime', e.cache_.currentTime)
    },
    changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
    getData() {
      getLiveDetail(this.$route.params.id).then(res=>{
        if(res.code === 0){

          this.content=res.data
          this.playerOptions.sources[0].src = res.data.pullUrl;

          console.log(res);
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .video-js {
  min-height: 500px !important;
}

::v-deep .vjs-current-time {
  display: none !important;
}
.container {
  width: 1200px;
    margin: 0 auto;
    .video-box {
      // height: 800px;
      background: url('https://oss.culturalcloud.net/furong/202311/02101728qrki.png') no-repeat center center scroll;
      background-size: 100% 100%;
      padding: 20px;
      h1 {
        font-size: 32px;
        line-height: 64px;
        color: #3A8DA6;
      }
      .info {
        font-size: 14px;
        margin-bottom: 20px;

      }
    }
}
</style>
